import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private apiRequestService: ApiRequestService) { }

  getPaymentList(obj, postBackURL) {
    return this.apiRequestService.get('paymentView',
      '?outletId=' + obj.outletId +
      '&mobile=' + obj.mobile +
      '&orderId=' + obj.orderId +
      '&amount=' + obj.amount +
      '&postBackURL=' + postBackURL);
  }

  createPayment(reqBody) {
    return this.apiRequestService.post('createPayment', reqBody);
  }

  createCustomPayment(reqBody) {
    return this.apiRequestService.post('createCustomPayment', reqBody);
  }

  getExtraNb(payToken, orderId) {
    return this.apiRequestService.get('extra',
      '?token=' + payToken +
      '&info=nbList' +
      '&orderId=' + orderId);
  }


  checkPayTmBalance(optionPayment, amount) {
    if (optionPayment.linked && optionPayment.id === 'paytm') {
      let payTmBal = 0;
      if (optionPayment.balanceInfo) {
        payTmBal = Number(optionPayment.balanceInfo.value);
      }
      // console.log("amount ", Number(amount));
      // console.log("payTmBal", payTmBal);
      if (payTmBal >= Number(amount)) {
        return { isInsufficientBal: false, bal: payTmBal, clr: '', size: '12px',
         lowBalanceText: "" };
      } else {
        return { isInsufficientBal: true, bal: payTmBal, clr: '', size: '12px',
         lowBalanceText: "Insufficient balance, Add funds to your wallet" };
      }
    }
  }

  // create object for cash payment
  cashPayReqObj(obj) {
    const reqBody = {
      orderId: obj.orderId,
      provider: 'cod',
      postBackURL: obj.postBackURL
    };
    return reqBody;
  }

  // Create common object for payment
  commonPayReqObj(obj) {
    const body = {
      orderId: obj.orderId,
      token: obj.paymentToken,
      postBackURL: obj.postBackURL,
    };
    return body;
  }

  // create object for custom payment methods
  customPayReqObj(activeRouteObj, paymentMode, type, hasLowBalance = false) {
    const body = {
      type,
      paymentMode,
      hasLowBalance,
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...body
    };
  }


  createPaymentObj(activeRouteObj, obj) {
    const reqBody = { orderId: activeRouteObj.orderId, provider: obj.id, postBackURL: activeRouteObj.postBackURL };
    return reqBody;
  }




  // create object for save card payment
  saveCardPayReqObj(activeRouteObj, saveCardObj) {
    const body = {
      isSave: true,
      cardDetails: {
        // 'number': saveCardObj.cardDetails.firstSixDigit + saveCardObj.cardDetails.lastFourDigit,
        number: saveCardObj.cardDetails.cardId,
        cvv: saveCardObj.cvv
      }
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...this.customPayReqObj(activeRouteObj, saveCardObj.cardDetails.cardType, 'card'),
      ...body
    };
  }


  newCardReqObj(activeRouteObj, newCardObj) {
    const body = {
      canSave: newCardObj.canSave,
      cardDetails: {
        number: newCardObj.cardNumber,
        cvv: newCardObj.cvv,
        expiry: newCardObj.expiryMonth
      }
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...this.customPayReqObj(activeRouteObj, newCardObj.paymentMode, 'card'),
      ...body
    };
  }

  // create object for net banking payment
  netBankingPayObj(activeRouteObj, payObj, channelObj) {
    const body = {
      code: channelObj.channelCode,
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...this.customPayReqObj(activeRouteObj, payObj.paymentMode, 'net'),
      ...body
    };
  }

  //create upi app intent object { intent }
  upiAppObj(activeRouteObj: object, app: string) {
    const body = {
      type: 'intent',
      app: app
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...body
    }
  }

  upiPayObj(activeRouteObj, vpa) {
    const body = {
      type: 'upi',
      vpa,
    };
    return {
      ...this.commonPayReqObj(activeRouteObj),
      ...body
    };
  }
}
